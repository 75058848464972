/*--------------------------------------------------------------------/
	works
/--------------------------------------------------------------------*/

@import "../_plugins/slick"; //slick

$slider_width_left: 720; // left px
$slider_width_right: 580; // right px

/* works-slider */
.works-slider {
	position: relative;
	height: auto;
	&__inner {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 50% 40%;
			grid-template-rows: 3rem 1fr;
			column-gap: 10%;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
		@include media-breakpoint-down(md) {
			@include flex-c-reverse;
		}
	}
	& .category-list__items {
		margin-bottom: 1rem;
		@include media-breakpoint-up(lg) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		span {
			width: 100%;
		}
	}
}

// works-slider__main
.works-slider__main {
	position: relative;
	width: 100%;
	height: auto;
	@include media-breakpoint-up(lg) {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 1.5rem;
	}
	& .sp-slide {
		position: relative;
		overflow: hidden;
		&__inner {
			@include aspect-img;
		}
		& figcaption {
			margin-top: 0.5rem;
			line-height: 1.4;
			z-index: 1;
			padding-left: 1.5rem;
			position: relative;
			&::before {
				content: "●";
				position: absolute;
				top: 0;
				left: 0;
				color: $l-gray;
			}
		}
	}
}

.works-slider__main {
	& .slick-arrow {
		background: rgba($white, 0.4);
		border: 1px solid rgba($main_c, 0.4);
		display: flex;
		align-items: center;
		@include transition;
		@include radius(50%);
		&:hover {
			background: rgba($main_c, 0.8);
			i::before {
				color: $white;
			}
		}
		& i {
			display: flex;
			justify-content: center;
			align-items: center;
			@include f-size(20);
			width: 30px;
			height: 30px;
			@include transition;
			@include media-breakpoint-up(lg) {
				width: 40px;
				height: 40px;
			}
			&::before {
				color: $main_c;
			}
		}
	}
}

// works-slider__thumbnail
.works-slider__thumbnail {
	display: flex;
	flex-wrap: wrap;
	column-gap: floor-decimal(20 / $slider_width_right * 100%);
	row-gap: clamp(0.5rem, 3.1vw, 33px);
	@include media-breakpoint-up(lg) {
		margin-top: 2rem;
		row-gap: clamp(0.5rem, 1.04vw, 20px);
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 2rem;
	}
	&__item {
		width: floor-decimal(100 / $slider_width_right * 100%);
	}
	&__inner {
		width: 100%;
		overflow: hidden;
		@include transition;
		@include aspect-img(1, 1);
		@include img-scale;
		cursor: pointer;
	}
}

/* スライダーを使用しない場合の一枚画像 */
.works-single {
	&__inner {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 50% 40%;
			grid-template-rows: 3rem 1fr;
			column-gap: 10%;
		}
	}
	@include media-breakpoint-up(lg) {
		grid-column: 2 / 3;
		grid-row: 2 / 3;
	}
	& .category-list__items {
		margin-bottom: 1rem;
		@include media-breakpoint-up(lg) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		span {
			width: 100%;
		}
	}
}
.works-single__main {
	position: relative;
	max-width: map-get($container-max-widths, lg); //メインコンテンツサイズ
	height: auto;
	margin-bottom: 2rem;
	@include media-breakpoint-up(lg) {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
	}
	&__img {
		@include aspect-img;
	}
	& figcaption {
		margin-top: 0.5rem;
		line-height: 1.4;
		z-index: 1;
		padding-left: 1.5rem;
		position: relative;
		&::before {
			content: "●";
			position: absolute;
			top: 0;
			left: 0;
			color: $l-gray;
		}
	}
}

/* スライダーなしの場合 */
.works-comment {
	padding: 2em 5%;
	border: 1px solid $m-gray;
	margin-bottom: clamp(3rem, 8vw, 8rem);
	@include media-breakpoint-up(xl) {
		padding: 3rem 6%;
	}
}

/* works-pdf */
.works-pdf {
	margin-top: clamp(3rem, 8vw, 8rem);
	&__inner {
		padding: 2em 5%;
		background: $p-gray;
		@include media-breakpoint-up(xl) {
			padding: 3rem 6%;
		}
	}
	&__list {
		@include media-breakpoint-up(lg) {
			@include flex-wrap;
			column-gap: 6%;
			& > * {
				width: 47%;
			}
		}
		& li {
			a {
				padding: 1rem 4% 1rem 4rem;
				@include transition;
				border: 1px solid $d-gray;
				position: relative;
				background: $white;
				width: 100%;
				height: 100%;
				display: block;
				color: $txt_c;
				@include dec-none;
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1.5rem;
					top: 0.9rem;
					@include transition;
					@include f-size(32);
					color: $d-gray;
				}
				&:hover {
					background: $gray;
					border-color: $gray;
					color: $white;
					@include transition;
					&::before {
						color: $white;
					}
				}
			}
		}
	}
}

/* works-interview */
$works_interview_txt_w: 48.33%; //テキストの横幅
$works_interview_img_w: 41.66%; //画像の横幅
.works-interview {
	margin-top: clamp(4rem, 8vw, 8rem);
	&__list {
		& > * {
			&:not(:last-child) {
				margin-bottom: clamp(3rem, 8vw, 8rem);
			}
			&:nth-of-type(even) {
				.works-interview__box__inner {
				}
			}
		}
	}
}

.works-interview__box {
	&__inner {
		@include media-breakpoint-up(md) {
			@include flex-between;
			flex-direction: row-reverse;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: $works_interview_txt_w;
			padding-top: 1rem;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
	}
	&__catch {
		position: relative;
		@include f-s_xs(1.1, 4);
		margin-bottom: calc(0.5rem + 0.5em);
		padding: 15px 10px 15px 1.5em;
		background: $black;
		color:  $white;
		@include f-w(500);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		&::before {
			content: "";
			width: 1rem;
			height: 1px;
			background: $gray;
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: $works_interview_img_w;
		}
		figcaption {
			margin-top: 0.5rem;
			line-height: 1.4;
			z-index: 1;
			padding-left: 1.5rem;
			position: relative;
			&::before {
				content: "●";
				position: absolute;
				top: 0;
				left: 0;
				color: $l-gray;
			}
		}
	}
}

/* works-video */
.works-video {
	margin-top: clamp(4rem, 8vw, 8rem);
	&__inner {
		overflow: hidden;
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 41.66% 1fr;
			grid-template-rows: auto 1fr;
			column-gap: 4.15%;
		}
		@include media-breakpoint-up(xl) {
			column-gap: 8.33%;
		}
	}
	&__tit {
		@include f-s_xs(1.14, 4);
		@include line-h(1.4);
		padding-bottom: 1.25rem;
		margin-bottom: 1.5rem;
		position: relative;
		@include media-breakpoint-up(md) {
			@include f-s_md(1.14, 4);
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		&::before {
			position: absolute;
			display: block;
			right: 0;
			bottom: 0;
			content: "";
			width: 100vw;
			height: 1px;
			background: $black;
		}
	}
	&__video {
		@include media-breakpoint-up(md) {
			position: relative;
			grid-column: 1 / 2;
			grid-row: 1 / 3;
			z-index: z-index(module, part03);
		}
		@include media-breakpoint-down(sm) {
			@include m-a;
			max-width: 480px;
			margin-bottom: 1rem;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
	}
}

/* works-table */
.works-table {
	margin-top: clamp(4rem, 8vw, 8rem);
	&__box {
		padding: 2rem 5%;
		background-color: $sub_c;
		@include media-breakpoint-up(lg) {
			padding: 3rem 8.33%;
		}
	}
	&__tit {
		text-align: center;
		line-height: 1.4;
		padding-bottom: calc(0.5rem + 0.3em);
		margin-bottom: calc(1.5em + 0.3em);
		position: relative;
		@include f-w(500);
		@include f-s_xs(1.14, 6);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

/* works-access */
.works-access {
	margin-top: clamp(4rem, 8vw, 8rem);
	&__tit {
		text-align: center;
		line-height: 1.4;
		padding-bottom: calc(0.5rem + 0.3em);
		margin-bottom: calc(1.5rem + 0.3em);
		position: relative;
		@include f-w(500);
		@include f-s_xs(1.14, 6);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__map {
		margin-bottom: 1rem;
		position: relative;
		padding: 0;
		height: 0;
		overflow: hidden;
		background-color: $white;
		@include media-breakpoint-up(lg) {
			padding-bottom: 40%;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 300px;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
}

.works-single__btn {
	margin-top: clamp(5rem, 8vw, 8rem);
}
