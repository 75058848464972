/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/
.page-contact {
	.main-view__bg__img {
		background-image: url("../img/common/bg_contact.jpg");
	}
}
.contact-intro {
	@include cont-margin-bottom;
	@include media-breakpoint-up(lg) {
		margin-top: 2rem;
	}
}
.contact-flex {
	@include media-breakpoint-up(lg) {
		display: flex;
		& > * {
			flex-basis: 50%;
			&:nth-of-type(even) {
				border-left: 1px solid $l-gray;
			}
		}
	}
}

.contact-box {
	&:first-child {
		margin-bottom: clamp(4rem, 10vw, 7rem);
	}
	&__tit {
		margin-bottom: 1.5em;
		overflow: hidden;
		span {
			@include fs_xxs(14, 22);
			@include f-w(500);
			display: inline-block;
			padding-right: 1em;
			position: relative;
			&::after {
				position: absolute;
				top: 50%;
				left: 100%;
				content: "";
				width: 500%;
				height: 1px;
				background: $gray;
			}
		}
	}
	&__tel {
		&__time {
			margin-bottom: 0.75rem;
			@include line_h(1.5);
			@include f-w(400);
		}
		&__num {
			@include f-family(font02);
			@include fs_xxs(16, 20);
			@include line_h(1);
			.tel-link {
				@include f-em(26);
				@include media-breakpoint-up(lg) {
					@include f-em(30);
				}
			}
		}
		a {
			color: $txt_c;
		}
	}
}

.contact-step {
	@include media-breakpoint-up(lg) {
		position: sticky;
		top: 120px;
		left: 0;
	}
	& ol {
		display: flex;
		flex-direction: column;
		& li {
			color: $txt_c;
			position: relative;
			@include f-em(17);
			display: flex;
			align-items: center;
			z-index: z-index(module, part04);
			&:not(:last-child) {
				margin-bottom: 1rem;
				&::after {
					position: absolute;
					content: "";
					background: $gray;
					top: 10px;
					left: 1.5rem;
					width: 1px;
					height: 180%;
					z-index: z-index(module, part04);
				}
			}
			span {
				width: 3rem;
				height: 20px;
				position: relative;
				z-index: z-index(module, part03);
				&::before {
					position: absolute;
					top: calc(50% - 10px);
					left: calc(50% - 10px);
					content: "";
					display: block;
					width: 20px;
					height: 20px;
					background: $white;
					border: 1px solid $gray;
					@include radius(50%);
				}
			}
			b {
				width: auto;
				@include f-w(500);
				@include media-breakpoint-up(lg) {
					padding-left: 1em;
				}
			}
			&.now {
				& span::after {
					position: absolute;
					top: calc(50% - 6px);
					left: calc(50% - 6px);
					content: "";
					display: block;
					width: 12px;
					height: 12px;
					background: $main_c;
					@include radius(50%);
				}
			}
		}
	}
}

.contact-privacy {
	border: 1px solid $gray;
	@include media-breakpoint-up(lg) {
		overflow-y: scroll;
		max-height: 300px;
		margin-top: 1.5rem;
		@include scroll-bar;
	}
	&__tit {
		@include f-s_xs(1.14, 2);
		@include f-w(400);
		text-align: center;
		@include media-breakpoint-up(lg) {
			padding: 3rem 10%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 6%;
			cursor: pointer;
			position: relative;
			@include icon(arrow-down, after);
			&::after {
				position: absolute;
				right: 1rem;
				top: calc(50% - 10px);
				@include transition;
				@include f-size(24);
			}
		}
		&.active {
			&::after {
				transform: rotate(180deg);
			}
		}
	}
	&__txt {
		@include media-breakpoint-down(md) {
			border-top: 1px solid $gray;
		}
	}
	.txt {
		@include media-breakpoint-up(md) {
			@include f-em(15);
		}
		@include media-breakpoint-up(lg) {
			padding: 0 10% 3rem;
		}
		@include media-breakpoint-down(md) {
			padding: 1rem 6% 3rem;
		}
	}
}

/*-------------------------------------------------
	contact form
-------------------------------------------------*/
/* variablea */
$radio_height: 26px; //チェックボックスの高さ
$duration_radio: 0.4s; //チェックボックスのアニメーションのスピード
$checkbox_height: 26px; //チェックボックスの高さ
$duration_checkbox: 0.4s; //チェックボックスのアニメーションのスピード

.contact-form {
	&__tit {
		text-align: center;
		@include f-family(font03,serif);
		color: $black;
		@include fs-xxs(20, 32);
		margin-bottom: clamp(2rem, 4vw, 3.5rem);
	}
	.btn-wrap {
		@include m-a;
		width: 100%;
		@include media-breakpoint-up(sm) {
			max-width: 320px;
		}
	}
}

.contact-table {
	width: 100%;
	@include m-a;
	line-height: 1.2;
	position: relative;
	img {
		width: 100%;
	}
	& th,
	& td {
		text-align: left;
		@include media-breakpoint-up(lg) {
			display: table-cell;
			padding: 1rem 0 2rem;
		}
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	& th {
		vertical-align: top;
		@include f-w(400);
		@include media-breakpoint-up(lg) {
			width: unquote("max(30%,280px)");
		}
		@include media-breakpoint-up(xl) {
			font-size: 1.12em;
		}
		@include media-breakpoint-down(md) {
			@include f-em(18);
		}
		& .required {
			padding: 0.2em 0.4em;
			@include line-h(1);
		}
	}
	& td {
		@include media-breakpoint-up(lg) {
			width: 64%;
			padding-left: 4%;
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 0 2.5rem;
			padding-top: 1.5rem;
		}
	}
	& textarea {
		width: 100%;
	}
}

input,
select {
	width: auto;
	&.long {
		width: 100%;
	}
	&.middle {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 70%;
		}
	}
	&.short {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}
	&.mini {
		width: 10em;
	}
}

button[class*="sub-btn"],
button[class*="back-btn"],
button[class*="chack-btn"] {
	@include f-size(18);
	@include l-sp(0.1em);
}
button[class*="sub-btn"] {
	@include btn_line($white, $main_c, $other_c2, $white, $point_c, $black);
}
button[class*="back-btn"] {
	margin-top: 1rem;
	@include btn_line($d-gray, $l-gray, $gray, $white, $black, $black);
}
button[class*="chack-btn"] {
	@include btn_line($white, $main_c, $other_c2, $white, $point_c, $black);
}

[type="button"],
[type="submit"] {
	-webkit-appearance: none;
}

/* radio-btn */
.mwform-radio-field {
	&:not(:last-child) {
		margin-right: 1em;
	}
	label {
		cursor: pointer;
	}
}
.mwform-radio-field-text {
	vertical-align: top;
	font-size: 1.07em;
}
input[type="radio"] {
	position: absolute;
	opacity: 0;
	+ .mwform-radio-field-text {
		&:before {
			content: "";
			background: $o-white;
			border-radius: 100%;
			border: 1px solid $gray;
			display: inline-block;
			width: $radio_height;
			height: $radio_height;
			position: relative;
			top: -0.1em;
			margin-right: 1em;
			vertical-align: top;
			cursor: pointer;
			text-align: center;
			transition: all $duration_radio ease;
		}
	}
	&:checked {
		+ .mwform-radio-field-text {
			&:before {
				background-color: $main_c;
				box-shadow: inset 0 0 0 5px $p-gray;
			}
		}
	}
	&:focus {
		+ .mwform-radio-field-text {
			&:before {
				outline: none;
				border-color: $main_c;
			}
		}
	}
	&:disabled {
		+ .mwform-radio-field-text {
			&:before {
				box-shadow: inset 0 0 0 4px $gray;
				border-color: $gray;
				background: $o-white;
			}
		}
	}
	+ .mwform-radio-field-text {
		&:empty {
			&:before {
				margin-right: 0;
			}
		}
	}
}
.vertical-item:not(:first-of-type) {
	margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@-webkit-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-webkit-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-moz-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}

input[type="checkbox"] {
	display: none;
}

.check-box {
	& .vertical-item:not(:first-of-type) {
		margin-top: 1.5rem !important;
	}
	& label {
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			height: $checkbox_height;
			width: $checkbox_height;
			background-color: transparent;
			border: 1px solid $gray;
			margin-right: 1em;
			position: absolute;
			top: -0.15em;
			left: 0;
			display: block;
			transition: border-color ease $duration_checkbox/2;
			z-index: z-index(module, part04);
		}
	}
}

.mwform-checkbox-field-text {
	vertical-align: top;
	padding-left: calc(#{$checkbox_height} + 1rem);
	display: block;
	font-size: 1.07em;
	&::before,
	&::after {
		position: absolute;
		height: 0;
		width: $checkbox_height * 0.2;
		background-color: $main_c;
		display: inline-block;
		transform-origin: left top;
		content: "";
		transition: opacity ease 0.5;
		z-index: z-index(module, part03);
	}
	&::before {
		top: $checkbox_height * 0.86;
		left: $checkbox_height * 0.4;
		transform: rotate(-135deg);
	}
	&::after {
		top: $checkbox_height * 0.5;
		left: $checkbox_height * 0.03;
		transform: rotate(-45deg);
	}
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
	border-color: $main_c;
	&::after {
		height: $checkbox_height * 0.5;
		animation: dothabottomcheck $duration_checkbox/2 ease 0s forwards;
	}

	&::before {
		height: $checkbox_height * 1.2;
		animation: dothatopcheck $duration_checkbox ease 0s forwards;
		box-shadow: 0 0 0 $checkbox_height * 0.05 $white;
	}
}

.mw_wp_form .error {
	margin-top: .7rem;
}

/* form-parts
----------------------------------------------------------------*/

%form-parts {
	background-color: $o-white;
	padding: 1em;
	border: 1px solid $gray;
	line-height: 1.5;
	&:focus {
		background-color: #fff;
		box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
	}
}

%placeholder {
	color: $gray;
}

input {
	@extend %form-parts;
	&::-webkit-input-placeholder {
		@extend %placeholder;
	}
	&:-moz-placeholder {
		@extend %placeholder;
	}
	&:-ms-input-placeholder {
		@extend %placeholder;
	}
}
textarea {
	@extend %form-parts;
	height: 10em;
}
select {
	@extend %form-parts;
}
