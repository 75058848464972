/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/

/*
AddToAny（シェア追加プラグイン）用　css
管理画面にて下記を設定
.addtoany_content{
	margin-top: 3rem;
	padding-top: 1.5rem;
	border-top:1px solid #ddd;
}
*/

.blog-single {
	&__inner {
		margin-bottom: clamp(4rem, 6vw, 6rem);
		@include clearfix;
	}
	&__sub {
		@include media-breakpoint-up(md) {
			display: flex;
		}
	}
	&__date {
		flex-shrink: 0;
		padding-right: 1rem;
		margin-bottom: 0.5rem;
	}
	.category-list__items {
		flex-shrink: 1;
	}
	&__tag {
		li {
			font-size: 1rem !important;
			color: $txt_c;
			padding-left: 1.5rem;
			margin-right: 0.5rem;
			position: relative;
			@include icon(tag, before);
			@include dec-none;
			&::before {
				position: absolute;
				top: 0.6em;
				left: 0.2rem;
				@include f-em(14);
				color: $gray;
				@include dec-none;
			}
		}
	}
	&__txt {
		margin-top: 1rem;
	}
}

/* align */
.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.alignright {
	float: right;
	margin-left: 1.5em;
}
.alignleft {
	float: left;
	margin-right: 1.5em;
}

/*-------------------------------------------------
/* single-nextprev
-------------------------------------------------*/
.single-nextprev {
	position: relative;
	margin-top: clamp(3rem, 5vw, 5rem);
	@include media-breakpoint-only(md) {
		display: grid;
		grid-template-columns: 50% 50%;
		&::after {
			content: "";
			position: absolute;
			width: 1px;
			height: calc(100% - 2em);
			left: 50%;
			top: 1em;
			background: $l-gray;
		}
	}
	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: 50% 50%;
		&::after {
			content: "";
			position: absolute;
			width: 1px;
			height: calc(100% - 2em);
			left: 50%;
			top: 1em;
			background: $l-gray;
		}
	}
	a {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		color: $txt_c;
		position: relative;
		@include dec-none;
		padding: 1em 0;
		border-top: 1px solid $l-gray;
		border-bottom: 1px solid $l-gray;
		@include transition;
	}
}
.single-nextprev {
	&__img {
		width: 70px;
		height: 70px;
		flex-shrink: 0;
		@include media-breakpoint-up(sm) {
			width: 80px;
			height: 80px;
		}
		&__inner {
			@include aspect-img(1, 1);
		}
	}
	&__txt {
		flex-shrink: 1;
		padding: .5em 1.5em 1.75rem;
		width: calc(100% - 80px);
		position: relative;
		@include media-breakpoint-up(sm) {
			width: calc(100% - 100px);
		}
	}
	&__tit {
		@include f-w(500);
		@include f-em(15);
	}
	&__time {
		@include f-family(font02);
		@include f-size(14);
		color: $gray;
	}
	&__arrow {
		@include f-size(13);
	}
	.next_post {
		position: relative;
		@include media-breakpoint-only(md) {
			grid-column: 1;
		}
		@include media-breakpoint-up(lg) {
			grid-column: 1;
		}
		a {
			justify-content: flex-end;
			flex-direction: row-reverse;
			&:hover {
				.single-nextprev__arrow {
					color: $main_c;
					margin-left: -5px;
				}
			}
		}
		.single-nextprev__arrow {
			position: absolute;
			left: 1.75em;
			bottom: 0;
			vertical-align: middle;
			vertical-align: top;
			@include icon(left_arrow);
			@include transition;
			&::before {
				@include f-size(20);
			}
		}
	}
	.prev_post {
		position: relative;
		@include media-breakpoint-only(md) {
			grid-column: 2;
		}
		@include media-breakpoint-up(lg) {
			grid-column: 2;
		}
		@include media-breakpoint-only(lg){
			top:-1px;
		}
		@include media-breakpoint-down(sm) {
			top:-1px;
		}
		a {
			justify-content: flex-end;
			&:hover {
				.single-nextprev__arrow {
					color: $main_c;
					margin-right: -5px;
				}
			}
		}
		.single-nextprev__txt {
			display: flex;
			justify-content: flex-end;
		}
		.single-nextprev__arrow {
			position: absolute;
			right: 1.75em;
			bottom: 0;
			vertical-align: top;
			@include icon(right_arrow, after);
			@include transition;
			&::after {
				@include f-size(20);
			}
		}
	}
}
