@charset "utf-8";
@import "base";
/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
$top_max_hight: unquote("max(150px,25vw)");
$top_pc_hight: 200px;

.main-view {
	position: relative;
	background: $black;
	height: $top_max_hight;
	padding-top: $header_hight_sp_top;
	@include media-breakpoint-up(lg) {
		padding-top: $header_hight_pc_top;
		height: $top_pc_hight;
	}
	& .l-cont {
		display: flex;
		align-items: center;
		height: 100%;
		z-index: z-index(module, part02);
		@include media-breakpoint-down(xs) {
			justify-content: center;
		}
	}
}

.main-view__tit {
	z-index: z-index(module, part01);
	width: 100%;
	&--eng {
		@include f-family(font03,serif);
		display: block;
		@include f-w(400);
		@include fs-xxs(20, 40);
		@include line-h(1.22);
		@include l-sp(0.05em);
		color: $white;
	}
	&--jp {
		@include fs-xs(12, 18);
		@include l-sp(0.175em);
		@include line-h(1.5);
		@include f-w(500);
		color: $white;
	}
	&--category {
		@include fs-xs(12, 32);
		@include l-sp(0.175em);
		@include line-h(1.5);
		@include f-w(600);
		color: $txt_c;
		color: $txt_c;
		span {
			@include f-family(font02);
			color: $main_c;
			@include l-sp(0);
			@include f-em(14);
			@include line-h(1);
			padding-right: 0.5em;
			display:block;
		}
	}
	&--tag {
		@include fs-xs(12, 32);
		@include l-sp(0.175em);
		@include line-h(1.5);
		@include f-w(600);
		color: $txt_c;
		span {
			@include f-family(font02);
			color: $main_c;
			@include l-sp(0);
			@include f-em(14);
			@include line-h(1);
			padding-right: 0.5em;
			display:block;
		}
	}
	&--date {
		@include fs-xs(12, 32);
		@include l-sp(0.175em);
		@include line-h(1.5);
		@include f-w(600);
		color: $txt_c;
	}
	&--404 {
		@include f-family(font02);
		display: block;
		@include f-w(400);
		@include fs-xxs(20, 48);
		@include line-h(1.22);
		@include l-sp(0);
		color: $main_c;
	}
}

//詳細ページ
.main-view__single {
	position: relative;
	height: $top_max_hight;
	padding-top: $header_hight_sp_top;
	@include media-breakpoint-up(lg) {
		padding-top: $header_hight_pc_top;
		height: $top_pc_hight;
	}
	& .l-cont {
		display: flex;
		align-items: center;
		height: 100%;
		z-index: z-index(module, part02);
	}
	&__tit {
		@include fs-xs(12, 28);
		@include l-sp(0.175em);
		@include line-h(1.5);
		@include f-w(600);
		color: $txt_c;
		&--singular { //実績紹介詳細
			@include fs-xs(16, 36);
			@include l-sp(0.1em);
			@include line-h(1.5);
			@include f-w(600);
			color: $txt_c;
		}
		&--single { //ブログ詳細
			@include fs-xs(15, 32);
			@include l-sp(0.1em);
			@include line-h(1.5);
			@include f-w(600);
			color: $txt_c;
		}
	}
}

/*--------------------------------------------------------------------/
	contents-flex
/--------------------------------------------------------------------*/
.contents {
	padding-bottom: clamp(4rem, 14vw, 10rem);
}

.contents-flex {
	width: 100%;
	position: relative;
	@include media-breakpoint-up(lg) {
		@include flex-between;
	}
}
.contents-left {
	width: 100%;
	@include media-breakpoint-up(lg) {
		max-width: #{$contents_left_w}px;
		width: floor-decimal(($contents_left_w/map-get($grid-num, xl) * 100%));
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 5rem;
	}
}
.contents-right {
	width: 100%;
	position: relative;
	@include media-breakpoint-up(lg) {
		max-width: #{$contents_right_w}px;
		width: floor-decimal(($contents_right_w/map-get($grid-num, xl) * 100%));
	}
}
/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont {
	&__txt {
		margin-bottom: 4rem;
		@include clearfix;
		@include media-breakpoint-up(md) {
			margin-bottom: 6rem;
		}
	}
	&__thumbnail {
		float: right;
		width: 50%;
		margin-left: 6%;
		margin-bottom: 3rem;
	}
}

.single-sub {
	margin-bottom: 1.5rem;
	@include media-breakpoint-up(sm) {
		margin-bottom: 2rem;
		display: flex;
		align-items: flex-start;
	}
}
.single-date {
	@include f-family(font02);
	line-height: 1.4;
	color: $gray;
	margin-right: 1rem;
}

@import "_components/breadcrumb"; //パンくずリスト
@import "_lv2/aside"; //サイドバー
@import "_lv2/works"; //制作実績
@import "_lv2/blog"; //ブログ
@import "_lv2/contact"; //お問い合わせ
