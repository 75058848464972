@charset "UTF-8";
/* webfont 変数　*/
/* common-title
-----------------------------------------------------*/
/* ofi */
@media (min-width: 1920px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1536px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1024px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1919px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1535px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1023px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1024px) and (max-width: 1535px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1536px) and (max-width: 1919px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  background: #0c0601;
  height: max(150px,25vw);
  padding-top: 64px;
}

@media (min-width: 1024px) {
  .main-view {
    padding-top: 90px;
    height: 200px;
  }
}

.main-view .l-cont {
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 3;
}

@media (max-width: 575px) {
  .main-view .l-cont {
    justify-content: center;
  }
}

.main-view__tit {
  z-index: 4;
  width: 100%;
}

.main-view__tit--eng {
  font-family: 'Noto Serif JP', serif;
  display: block;
  font-weight: 400;
  font-size: clamp(20px, 7.14vw, 40px);
  line-height: 1.22;
  letter-spacing: 0.05em;
  color: #fff;
}

.main-view__tit--jp {
  font-size: clamp(12px, 3.19vw, 18px);
  letter-spacing: 0.175em;
  line-height: 1.5;
  font-weight: 500;
  color: #fff;
}

.main-view__tit--category {
  font-size: clamp(12px, 3.19vw, 32px);
  letter-spacing: 0.175em;
  line-height: 1.5;
  font-weight: 600;
  color: #202020;
  color: #202020;
}

.main-view__tit--category span {
  font-family: 'Oswald', sans-serif;
  color: #D20F0F;
  letter-spacing: 0;
  font-size: 0.87em;
  line-height: 1;
  padding-right: 0.5em;
  display: block;
}

.main-view__tit--tag {
  font-size: clamp(12px, 3.19vw, 32px);
  letter-spacing: 0.175em;
  line-height: 1.5;
  font-weight: 600;
  color: #202020;
}

.main-view__tit--tag span {
  font-family: 'Oswald', sans-serif;
  color: #D20F0F;
  letter-spacing: 0;
  font-size: 0.87em;
  line-height: 1;
  padding-right: 0.5em;
  display: block;
}

.main-view__tit--date {
  font-size: clamp(12px, 3.19vw, 32px);
  letter-spacing: 0.175em;
  line-height: 1.5;
  font-weight: 600;
  color: #202020;
}

.main-view__tit--404 {
  font-family: 'Oswald', sans-serif;
  display: block;
  font-weight: 400;
  font-size: clamp(20px, 7.14vw, 48px);
  line-height: 1.22;
  letter-spacing: 0;
  color: #D20F0F;
}

.main-view__single {
  position: relative;
  height: max(150px,25vw);
  padding-top: 64px;
}

@media (min-width: 1024px) {
  .main-view__single {
    padding-top: 90px;
    height: 200px;
  }
}

.main-view__single .l-cont {
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 3;
}

.main-view__single__tit {
  font-size: clamp(12px, 3.19vw, 28px);
  letter-spacing: 0.175em;
  line-height: 1.5;
  font-weight: 600;
  color: #202020;
}

.main-view__single__tit--singular {
  font-size: clamp(16px, 4.26vw, 36px);
  letter-spacing: 0.1em;
  line-height: 1.5;
  font-weight: 600;
  color: #202020;
}

.main-view__single__tit--single {
  font-size: clamp(15px, 3.99vw, 32px);
  letter-spacing: 0.1em;
  line-height: 1.5;
  font-weight: 600;
  color: #202020;
}

/*--------------------------------------------------------------------/
	contents-flex
/--------------------------------------------------------------------*/
.contents {
  padding-bottom: clamp(4rem, 14vw, 10rem);
}

.contents-flex {
  width: 100%;
  position: relative;
}

@media (min-width: 1024px) {
  .contents-flex {
    display: flex;
    justify-content: space-between;
  }
}

.contents-left {
  width: 100%;
}

@media (min-width: 1024px) {
  .contents-left {
    max-width: 940px;
    width: 65.27%;
  }
}

@media (max-width: 1023px) {
  .contents-left {
    margin-bottom: 5rem;
  }
}

.contents-right {
  width: 100%;
  position: relative;
}

@media (min-width: 1024px) {
  .contents-right {
    max-width: 320px;
    width: 22.22%;
  }
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont__txt {
  margin-bottom: 4rem;
}

.single-cont__txt::after {
  display: block;
  content: "";
  clear: both;
}

@media (min-width: 768px) {
  .single-cont__txt {
    margin-bottom: 6rem;
  }
}

.single-cont__thumbnail {
  float: right;
  width: 50%;
  margin-left: 6%;
  margin-bottom: 3rem;
}

.single-sub {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .single-sub {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
  }
}

.single-date {
  font-family: 'Oswald', sans-serif;
  line-height: 1.4;
  color: #979DA4;
  margin-right: 1rem;
}

/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  padding: 2rem 0;
  position: relative;
  z-index: 2;
}

@media (min-width: 1024px) {
  .breadcrumb {
    padding: 3rem 0;
  }
}

.breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
}

.breadcrumb-list .icon-home {
  font-size: 1.1em;
}

.breadcrumb-list li {
  position: relative;
}

.breadcrumb-list li:not(:last-child) {
  padding-right: 1.5rem;
}

.breadcrumb-list li:not(:last-child):after {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumb-list li:not(:last-child)::after {
  position: absolute;
  right: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
}

.breadcrumb-list a {
  color: #979DA4;
  transition: all 0.3s ease 0s;
  vertical-align: top;
}

.breadcrumb-list a:first-child {
  text-decoration: none !important;
}

.breadcrumb-list a:hover {
  color: #D20F0F;
}

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
@media (min-width: 1024px) {
  aside {
    position: sticky;
    top: 120px;
    left: 0;
  }
}

.aside-widget:not(:last-child) {
  margin-bottom: 2.5rem;
}

.aside-widget__tit {
  padding: 0.3rem 0 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #c9ced2;
  font-weight: 500;
  font-size: 1.12em;
}

.aside-widget ul li a {
  padding-left: 1.3em;
  color: #202020;
  text-decoration: none !important;
  position: relative;
  display: block;
}

.aside-widget ul li a::before {
  content: "●";
  position: absolute;
  font-size: 0.8em;
  top: .15em;
  left: 0;
  font-size: 0.87em;
  padding-right: 0.2em;
  color: #979DA4;
  text-decoration: none !important;
}

.aside-widget ul li a:hover {
  text-decoration: underline !important;
}

.tag-cloud-link {
  font-size: 1rem !important;
  color: #202020;
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  position: relative;
  text-decoration: none !important;
}

.tag-cloud-link:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tag-cloud-link::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.87em;
  color: #979DA4;
  text-decoration: none !important;
}

.tag-cloud-link:hover {
  text-decoration: underline !important;
}

/* calendar
------------------------------------*/
#wp-calendar {
  border-collapse: separate;
  width: 100%;
}

.calendar_wrap {
  padding: 0.5rem 0.75rem;
  border: 1px solid #c9ced2;
}

.wp-calendar-table {
  margin-bottom: 0.75rem;
}

.wp-calendar-table caption {
  padding: 0.3rem 2%;
}

.wp-calendar-table thead th {
  text-align: center;
  padding: 6px;
  width: auto;
  color: #979DA4;
  font-weight: 500;
}

.wp-calendar-table td {
  text-align: center;
  background: #f6f6f6;
  line-height: 2;
  border: 2px solid #fff;
  font-size: 0.93em;
}

.wp-calendar-table td a {
  background-color: #D20F0F;
  color: #fff;
  transition: all 0.3s ease 0s;
  display: block;
  text-decoration: none !important;
}

.wp-calendar-table td a:hover {
  background-color: #F1EADD;
}

.wp-calendar-table td.pad {
  background: transparent;
}

.wp-calendar-table td#today {
  background: #979DA4;
  color: #fff;
}

.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.3rem;
}

.wp-calendar-nav a {
  display: block;
  color: #202020;
  padding: 0.4rem 1rem;
  background-color: #f6f6f6;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}

.wp-calendar-nav a:hover {
  background-color: #979DA4;
  color: #fff;
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.wp-block-search__inside-wrapper {
  position: relative;
  width: 100%;
  border: 1px solid #979DA4 !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
}

.wp-block-search__input {
  height: 40px;
  padding: 0 10px !important;
  border-radius: 2px !important;
  outline: 0;
  background: #fff;
  appearance: none;
  border: none;
  width: calc(100% - 50px);
}

.wp-block-search__button {
  height: 40px !important;
  width: 50px !important;
  padding: 2px !important;
  background: none !important;
  font-size: 18px !important;
  vertical-align: bottom !important;
  color: #c9ced2 !important;
  background: #fff !important;
  transition: all 0.3s ease 0s;
  border: none !important;
  text-align: center;
  cursor: pointer !important;
}

.wp-block-search__button:hover {
  color: #fff !important;
  background-color: #D20F0F !important;
}

/*--------------------------------------------------------------------/
	archives
/--------------------------------------------------------------------*/
.wp-block-archives > label {
  display: none !important;
}

.wp-block-archives select {
  width: 80% !important;
}

@media (max-width: 1023px) {
  .wp-block-archives select {
    width: 100% !important;
  }
}

/*--------------------------------------------------------------------/
	works
/--------------------------------------------------------------------*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 4;
  /*&.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: $slick-font-family;
        font-size: 20px;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }*/
}

@media (min-width: 1024px) {
  .slick-prev,
  .slick-next {
    height: 40px;
    width: 40px;
  }
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev {
  left: -15px;
}

@media (min-width: 1024px) {
  .slick-prev {
    left: -20px;
  }
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -15px;
}

@media (min-width: 1024px) {
  [dir="rtl"] .slick-prev {
    right: -20px;
  }
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -15px;
}

@media (min-width: 1024px) {
  .slick-next {
    right: -20px;
  }
}

[dir="rtl"] .slick-next {
  left: -15px;
  right: auto;
}

@media (min-width: 1024px) {
  [dir="rtl"] .slick-next {
    left: -20px;
  }
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/** works-slider **/
.gallery-top {
  position: relative;
}

@media (min-width: 1024px) {
  .gallery-top {
    width: 720px;
  }
}

@media (max-width: 1023px) {
  .gallery-top {
    margin-bottom: 1.5rem;
  }
}

.gallery-top__inner {
  width: 100%;
  position: relative;
}

.gallery-top .slick-arrow {
  border: 1px solid #c9ced2;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;
  border-radius: 50%;
}

.gallery-top .slick-arrow:hover {
  background: rgba(12, 6, 1, 0.8);
}

.gallery-top .slick-arrow i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  width: 30px;
  height: 30px;
}

@media (min-width: 1024px) {
  .gallery-top .slick-arrow i {
    width: 40px;
    height: 40px;
  }
}

.gallery-top .slick-arrow i::before {
  color: #c9ced2;
}

.gallery-top .slick-prev::before {
  display: none;
}

.gallery-top .slick-next::before {
  display: none;
}

.gallery-top .sp-slide {
  position: relative;
  overflow: hidden;
}

.gallery-top .sp-slide img {
  height: 60.31vw;
}

@media (min-width: 1024px) {
  .gallery-top .sp-slide img {
    max-height: 480px;
  }
}

.gallery-top .sp-slide figcaption {
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  display: inline-block;
  background: #0c0601;
  color: #fff;
  line-height: 1.4;
  padding: 0.2rem 0.4rem;
  font-size: 0.8em;
  z-index: 1;
}

.gallery-thumbs {
  position: relative;
}

@media (min-width: 1024px) {
  .gallery-thumbs {
    position: absolute;
    top: 50%;
    right: 0;
    min-width: 138px;
    width: 16%;
    padding: 0;
    transform: translateY(-50%);
  }
}

.gallery-thumbs__inner {
  width: 100%;
  position: relative;
}

.gallery-thumbs .sp-thumbnail {
  transition: all 0.3s ease 0s;
  border: 1px solid #c9ced2;
}

.gallery-thumbs .sp-thumbnail:hover {
  opacity: 0.6;
}

.gallery-thumbs .sp-thumbnail img {
  height: 11.97vw;
}

@media (min-width: 1024px) {
  .gallery-thumbs .sp-thumbnail img {
    height: 90px;
  }
}

.gallery-thumbs .slick-arrow {
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  border: 1px solid #c9ced2;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  border-radius: 50%;
}

@media (min-width: 1024px) {
  .gallery-thumbs .slick-arrow {
    left: 50%;
    transform: translateX(-50%);
  }
}

.gallery-thumbs .slick-arrow:hover {
  background: rgba(12, 6, 1, 0.8);
}

.gallery-thumbs .slick-arrow i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  width: 30px;
  height: 30px;
}

@media (min-width: 1024px) {
  .gallery-thumbs .slick-arrow i {
    width: 40px;
    height: 40px;
  }
}

.gallery-thumbs .slick-arrow i::before {
  color: #c9ced2;
}

@media (min-width: 1024px) {
  .gallery-thumbs .slick-arrow i::before {
    transform: rotate(90deg);
  }
}

@media (min-width: 1024px) {
  .gallery-thumbs .slick-prev {
    top: -20px;
  }
}

@media (max-width: 1023px) {
  .gallery-thumbs .slick-prev {
    left: -15px;
  }
}

@media (min-width: 1024px) {
  .gallery-thumbs .slick-next {
    top: calc(100% - 20px);
  }
}

@media (max-width: 1023px) {
  .gallery-thumbs .slick-next {
    right: -15px;
  }
}

.gallery-thumbs .slider-nav {
  background: #e0e4e6;
}

.gallery-thumbs .slick-list {
  padding: 0 !important;
}

@media (min-width: 1024px) {
  .gallery-thumbs .slick-list {
    padding: 0 0 !important;
  }
}

.gallery-thumbs .slick-track {
  left: 0 !important;
  width: 100%;
  opacity: 1;
}

@media (min-width: 1024px) {
  .gallery-thumbs .slick-track {
    left: 0 !important;
  }
}

.gallery-thumbs .slick-slide {
  transition: all 0.3s ease 0s;
  opacity: 1;
}

@media (min-width: 1024px) {
  .gallery-thumbs .slick-slide {
    max-height: 92px;
    padding: 0;
    margin: 2px 0;
  }
}

@media (max-width: 1023px) {
  .gallery-thumbs .slick-slide {
    margin-right: 6px;
  }
}

/* works-slider */
.works-slider {
  position: relative;
  height: auto;
}

@media (min-width: 1024px) {
  .works-slider__inner {
    display: grid;
    grid-template-columns: 50% 40%;
    grid-template-rows: 3rem 1fr;
    column-gap: 10%;
  }
}

@media (min-width: 1024px) {
  .works-slider__txt {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}

@media (max-width: 1023px) {
  .works-slider__txt {
    display: flex;
    flex-direction: column-reverse;
  }
}

.works-slider .category-list__items {
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {
  .works-slider .category-list__items {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

.works-slider .category-list__items span {
  width: 100%;
}

.works-slider__main {
  position: relative;
  width: 100%;
  height: auto;
}

@media (min-width: 1024px) {
  .works-slider__main {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}

@media (max-width: 1023px) {
  .works-slider__main {
    margin-bottom: 1.5rem;
  }
}

.works-slider__main .sp-slide {
  position: relative;
  overflow: hidden;
}

.works-slider__main .sp-slide__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.works-slider__main .sp-slide__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.works-slider__main .sp-slide__inner > div,
.works-slider__main .sp-slide__inner > figure,
.works-slider__main .sp-slide__inner > a,
.works-slider__main .sp-slide__inner > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.works-slider__main .sp-slide figcaption {
  margin-top: 0.5rem;
  line-height: 1.4;
  z-index: 1;
  padding-left: 1.5rem;
  position: relative;
}

.works-slider__main .sp-slide figcaption::before {
  content: "●";
  position: absolute;
  top: 0;
  left: 0;
  color: #e0e4e6;
}

.works-slider__main .slick-arrow {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(210, 15, 15, 0.4);
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;
  border-radius: 50%;
}

.works-slider__main .slick-arrow:hover {
  background: rgba(210, 15, 15, 0.8);
}

.works-slider__main .slick-arrow:hover i::before {
  color: #fff;
}

.works-slider__main .slick-arrow i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  width: 30px;
  height: 30px;
  transition: all 0.3s ease 0s;
}

@media (min-width: 1024px) {
  .works-slider__main .slick-arrow i {
    width: 40px;
    height: 40px;
  }
}

.works-slider__main .slick-arrow i::before {
  color: #D20F0F;
}

.works-slider__thumbnail {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3.44%;
  row-gap: clamp(0.5rem, 3.1vw, 33px);
}

@media (min-width: 1024px) {
  .works-slider__thumbnail {
    margin-top: 2rem;
    row-gap: clamp(0.5rem, 1.04vw, 20px);
  }
}

@media (max-width: 1023px) {
  .works-slider__thumbnail {
    margin-bottom: 2rem;
  }
}

.works-slider__thumbnail__item {
  width: 17.24%;
}

.works-slider__thumbnail__inner {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.works-slider__thumbnail__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}

.works-slider__thumbnail__inner > div,
.works-slider__thumbnail__inner > figure,
.works-slider__thumbnail__inner > a,
.works-slider__thumbnail__inner > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.works-slider__thumbnail__inner figure {
  overflow: hidden;
}

.works-slider__thumbnail__inner img {
  transition: all 0.3s ease 0s;
}

.works-slider__thumbnail__inner:hover img {
  transform: scale(1.1, 1.1);
}

/* スライダーを使用しない場合の一枚画像 */
@media (min-width: 1024px) {
  .works-single__inner {
    display: grid;
    grid-template-columns: 50% 40%;
    grid-template-rows: 3rem 1fr;
    column-gap: 10%;
  }
}

@media (min-width: 1024px) {
  .works-single {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}

.works-single .category-list__items {
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {
  .works-single .category-list__items {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

.works-single .category-list__items span {
  width: 100%;
}

.works-single__main {
  position: relative;
  max-width: 1000px;
  height: auto;
  margin-bottom: 2rem;
}

@media (min-width: 1024px) {
  .works-single__main {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}

.works-single__main__img {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.works-single__main__img::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.works-single__main__img > div,
.works-single__main__img > figure,
.works-single__main__img > a,
.works-single__main__img > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.works-single__main figcaption {
  margin-top: 0.5rem;
  line-height: 1.4;
  z-index: 1;
  padding-left: 1.5rem;
  position: relative;
}

.works-single__main figcaption::before {
  content: "●";
  position: absolute;
  top: 0;
  left: 0;
  color: #e0e4e6;
}

/* スライダーなしの場合 */
.works-comment {
  padding: 2em 5%;
  border: 1px solid #c9ced2;
  margin-bottom: clamp(3rem, 8vw, 8rem);
}

@media (min-width: 1536px) {
  .works-comment {
    padding: 3rem 6%;
  }
}

/* works-pdf */
.works-pdf {
  margin-top: clamp(3rem, 8vw, 8rem);
}

.works-pdf__inner {
  padding: 2em 5%;
  background: #f6f6f6;
}

@media (min-width: 1536px) {
  .works-pdf__inner {
    padding: 3rem 6%;
  }
}

@media (min-width: 1024px) {
  .works-pdf__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 6%;
  }
  .works-pdf__list > * {
    width: 47%;
  }
}

.works-pdf__list li a {
  padding: 1rem 4% 1rem 4rem;
  transition: all 0.3s ease 0s;
  border: 1px solid #4B4D4E;
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  display: block;
  color: #202020;
  text-decoration: none !important;
}

.works-pdf__list li a:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.works-pdf__list li a::before {
  position: absolute;
  left: 1.5rem;
  top: 0.9rem;
  transition: all 0.3s ease 0s;
  font-size: 2rem;
  color: #4B4D4E;
}

.works-pdf__list li a:hover {
  background: #979DA4;
  border-color: #979DA4;
  color: #fff;
  transition: all 0.3s ease 0s;
}

.works-pdf__list li a:hover::before {
  color: #fff;
}

/* works-interview */
.works-interview {
  margin-top: clamp(4rem, 8vw, 8rem);
}

.works-interview__list > *:not(:last-child) {
  margin-bottom: clamp(3rem, 8vw, 8rem);
}

@media (min-width: 768px) {
  .works-interview__box__inner {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

@media (min-width: 768px) {
  .works-interview__box__txt {
    width: 48.33%;
    padding-top: 1rem;
  }
}

@media (max-width: 767px) {
  .works-interview__box__txt {
    margin-bottom: 1rem;
  }
}

.works-interview__box__catch {
  position: relative;
  font-size: calc(1.1rem + 4 * (100vw - 280px) / 1160);
  margin-bottom: calc(0.5rem + 0.5em);
  padding: 15px 10px 15px 1.5em;
  background: #0c0601;
  color: #fff;
  font-weight: 500;
}

@media (min-width: 768px) {
  .works-interview__box__catch {
    font-size: calc( 1.1rem + 4 * (100vw - 768px) / 672);
  }
}

@media (min-width: 1536px) {
  .works-interview__box__catch {
    font-size: 1.5rem;
  }
}

.works-interview__box__catch::before {
  content: "";
  width: 1rem;
  height: 1px;
  background: #979DA4;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

@media (min-width: 768px) {
  .works-interview__box__img {
    width: 41.66%;
  }
}

.works-interview__box__img figcaption {
  margin-top: 0.5rem;
  line-height: 1.4;
  z-index: 1;
  padding-left: 1.5rem;
  position: relative;
}

.works-interview__box__img figcaption::before {
  content: "●";
  position: absolute;
  top: 0;
  left: 0;
  color: #e0e4e6;
}

/* works-video */
.works-video {
  margin-top: clamp(4rem, 8vw, 8rem);
}

.works-video__inner {
  overflow: hidden;
}

@media (min-width: 768px) {
  .works-video__inner {
    display: grid;
    grid-template-columns: 41.66% 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 4.15%;
  }
}

@media (min-width: 1536px) {
  .works-video__inner {
    column-gap: 8.33%;
  }
}

.works-video__tit {
  font-size: calc(1.14rem + 4 * (100vw - 280px) / 1160);
  line-height: 1.4;
  padding-bottom: 1.25rem;
  margin-bottom: 1.5rem;
  position: relative;
}

@media (min-width: 768px) {
  .works-video__tit {
    font-size: calc( 1.14rem + 4 * (100vw - 768px) / 672);
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

@media (min-width: 1536px) {
  .works-video__tit {
    font-size: 1.5rem;
  }
}

.works-video__tit::before {
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  content: "";
  width: 100vw;
  height: 1px;
  background: #0c0601;
}

@media (min-width: 768px) {
  .works-video__video {
    position: relative;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    z-index: 2;
  }
}

@media (max-width: 767px) {
  .works-video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 1rem;
  }
}

.works-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.works-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .works-video__txt {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}

/* works-table */
.works-table {
  margin-top: clamp(4rem, 8vw, 8rem);
}

.works-table__box {
  padding: 2rem 5%;
  background-color: #F1EADD;
}

@media (min-width: 1024px) {
  .works-table__box {
    padding: 3rem 8.33%;
  }
}

.works-table__tit {
  text-align: center;
  line-height: 1.4;
  padding-bottom: calc(0.5rem + 0.3em);
  margin-bottom: calc(1.5em + 0.3em);
  position: relative;
  font-weight: 500;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .works-table__tit {
    font-size: 1.5rem;
  }
}

/* works-access */
.works-access {
  margin-top: clamp(4rem, 8vw, 8rem);
}

.works-access__tit {
  text-align: center;
  line-height: 1.4;
  padding-bottom: calc(0.5rem + 0.3em);
  margin-bottom: calc(1.5rem + 0.3em);
  position: relative;
  font-weight: 500;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1160);
}

@media (min-width: 1536px) {
  .works-access__tit {
    font-size: 1.5rem;
  }
}

.works-access__map {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  height: 0;
  overflow: hidden;
  background-color: #fff;
}

@media (min-width: 1024px) {
  .works-access__map {
    padding-bottom: 40%;
  }
}

@media (max-width: 1023px) {
  .works-access__map {
    padding-bottom: 300px;
  }
}

.works-access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.works-single__btn {
  margin-top: clamp(5rem, 8vw, 8rem);
}

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
/*
AddToAny（シェア追加プラグイン）用　css
管理画面にて下記を設定
.addtoany_content{
	margin-top: 3rem;
	padding-top: 1.5rem;
	border-top:1px solid #ddd;
}
*/
.blog-single__inner {
  margin-bottom: clamp(4rem, 6vw, 6rem);
}

.blog-single__inner::after {
  display: block;
  content: "";
  clear: both;
}

@media (min-width: 768px) {
  .blog-single__sub {
    display: flex;
  }
}

.blog-single__date {
  flex-shrink: 0;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}

.blog-single .category-list__items {
  flex-shrink: 1;
}

.blog-single__tag li {
  font-size: 1rem !important;
  color: #202020;
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  position: relative;
  text-decoration: none !important;
}

.blog-single__tag li:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.blog-single__tag li::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.87em;
  color: #979DA4;
  text-decoration: none !important;
}

.blog-single__txt {
  margin-top: 1rem;
}

/* align */
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignright {
  float: right;
  margin-left: 1.5em;
}

.alignleft {
  float: left;
  margin-right: 1.5em;
}

/*-------------------------------------------------
/* single-nextprev
-------------------------------------------------*/
.single-nextprev {
  position: relative;
  margin-top: clamp(3rem, 5vw, 5rem);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .single-nextprev {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .single-nextprev::after {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 2em);
    left: 50%;
    top: 1em;
    background: #e0e4e6;
  }
}

@media (min-width: 1024px) {
  .single-nextprev {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .single-nextprev::after {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 2em);
    left: 50%;
    top: 1em;
    background: #e0e4e6;
  }
}

.single-nextprev a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  color: #202020;
  position: relative;
  text-decoration: none !important;
  padding: 1em 0;
  border-top: 1px solid #e0e4e6;
  border-bottom: 1px solid #e0e4e6;
  transition: all 0.3s ease 0s;
}

.single-nextprev__img {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
}

@media (min-width: 576px) {
  .single-nextprev__img {
    width: 80px;
    height: 80px;
  }
}

.single-nextprev__img__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.single-nextprev__img__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}

.single-nextprev__img__inner > div,
.single-nextprev__img__inner > figure,
.single-nextprev__img__inner > a,
.single-nextprev__img__inner > picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.single-nextprev__txt {
  flex-shrink: 1;
  padding: .5em 1.5em 1.75rem;
  width: calc(100% - 80px);
  position: relative;
}

@media (min-width: 576px) {
  .single-nextprev__txt {
    width: calc(100% - 100px);
  }
}

.single-nextprev__tit {
  font-weight: 500;
  font-size: 0.93em;
}

.single-nextprev__time {
  font-family: 'Oswald', sans-serif;
  font-size: 0.88rem;
  color: #979DA4;
}

.single-nextprev__arrow {
  font-size: 0.81rem;
}

.single-nextprev .next_post {
  position: relative;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .single-nextprev .next_post {
    grid-column: 1;
  }
}

@media (min-width: 1024px) {
  .single-nextprev .next_post {
    grid-column: 1;
  }
}

.single-nextprev .next_post a {
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.single-nextprev .next_post a:hover .single-nextprev__arrow {
  color: #D20F0F;
  margin-left: -5px;
}

.single-nextprev .next_post .single-nextprev__arrow {
  position: absolute;
  left: 1.75em;
  bottom: 0;
  vertical-align: middle;
  vertical-align: top;
  transition: all 0.3s ease 0s;
}

.single-nextprev .next_post .single-nextprev__arrow:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.single-nextprev .next_post .single-nextprev__arrow::before {
  font-size: 1.25rem;
}

.single-nextprev .prev_post {
  position: relative;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .single-nextprev .prev_post {
    grid-column: 2;
  }
}

@media (min-width: 1024px) {
  .single-nextprev .prev_post {
    grid-column: 2;
  }
}

@media (min-width: 1024px) and (max-width: 1535px) {
  .single-nextprev .prev_post {
    top: -1px;
  }
}

@media (max-width: 767px) {
  .single-nextprev .prev_post {
    top: -1px;
  }
}

.single-nextprev .prev_post a {
  justify-content: flex-end;
}

.single-nextprev .prev_post a:hover .single-nextprev__arrow {
  color: #D20F0F;
  margin-right: -5px;
}

.single-nextprev .prev_post .single-nextprev__txt {
  display: flex;
  justify-content: flex-end;
}

.single-nextprev .prev_post .single-nextprev__arrow {
  position: absolute;
  right: 1.75em;
  bottom: 0;
  vertical-align: top;
  transition: all 0.3s ease 0s;
}

.single-nextprev .prev_post .single-nextprev__arrow:after {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.single-nextprev .prev_post .single-nextprev__arrow::after {
  font-size: 1.25rem;
}

/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/
.page-contact .main-view__bg__img {
  background-image: url("../img/common/bg_contact.jpg");
}

.contact-intro {
  margin-bottom: clamp(4rem, 8vw, 10rem);
}

@media (min-width: 1024px) {
  .contact-intro {
    margin-top: 2rem;
  }
}

@media (min-width: 1024px) {
  .contact-flex {
    display: flex;
  }
  .contact-flex > * {
    flex-basis: 50%;
  }
  .contact-flex > *:nth-of-type(even) {
    border-left: 1px solid #e0e4e6;
  }
}

.contact-box:first-child {
  margin-bottom: clamp(4rem, 10vw, 7rem);
}

.contact-box__tit {
  margin-bottom: 1.5em;
  overflow: hidden;
}

.contact-box__tit span {
  font-size: clamp(14px, 5vw, 22px);
  font-weight: 500;
  display: inline-block;
  padding-right: 1em;
  position: relative;
}

.contact-box__tit span::after {
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
  width: 500%;
  height: 1px;
  background: #979DA4;
}

.contact-box__tel__time {
  margin-bottom: 0.75rem;
  line-height: 1.5;
  font-weight: 400;
}

.contact-box__tel__num {
  font-family: 'Oswald', sans-serif;
  font-size: clamp(16px, 5.71vw, 20px);
  line-height: 1;
}

.contact-box__tel__num .tel-link {
  font-size: 1.62em;
}

@media (min-width: 1024px) {
  .contact-box__tel__num .tel-link {
    font-size: 1.87em;
  }
}

.contact-box__tel a {
  color: #202020;
}

@media (min-width: 1024px) {
  .contact-step {
    position: sticky;
    top: 120px;
    left: 0;
  }
}

.contact-step ol {
  display: flex;
  flex-direction: column;
}

.contact-step ol li {
  color: #202020;
  position: relative;
  font-size: 1.06em;
  display: flex;
  align-items: center;
  z-index: 1;
}

.contact-step ol li:not(:last-child) {
  margin-bottom: 1rem;
}

.contact-step ol li:not(:last-child)::after {
  position: absolute;
  content: "";
  background: #979DA4;
  top: 10px;
  left: 1.5rem;
  width: 1px;
  height: 180%;
  z-index: 1;
}

.contact-step ol li span {
  width: 3rem;
  height: 20px;
  position: relative;
  z-index: 2;
}

.contact-step ol li span::before {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #979DA4;
  border-radius: 50%;
}

.contact-step ol li b {
  width: auto;
  font-weight: 500;
}

@media (min-width: 1024px) {
  .contact-step ol li b {
    padding-left: 1em;
  }
}

.contact-step ol li.now span::after {
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: #D20F0F;
  border-radius: 50%;
}

.contact-privacy {
  border: 1px solid #979DA4;
}

@media (min-width: 1024px) {
  .contact-privacy {
    overflow-y: scroll;
    max-height: 300px;
    margin-top: 1.5rem;
  }
  .contact-privacy::-webkit-scrollbar {
    width: 8px;
  }
  .contact-privacy::-webkit-scrollbar-track {
    background: transparent;
  }
  .contact-privacy::-webkit-scrollbar-thumb {
    background: #c9ced2;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #fff;
  }
}

.contact-privacy__tit {
  font-size: calc(1.14rem + 2 * (100vw - 280px) / 1160);
  font-weight: 400;
  text-align: center;
}

@media (min-width: 1024px) {
  .contact-privacy__tit {
    padding: 3rem 10%;
  }
}

@media (min-width: 1536px) {
  .contact-privacy__tit {
    font-size: 1.25rem;
  }
}

@media (max-width: 1023px) {
  .contact-privacy__tit {
    padding: 1.5rem 6%;
    cursor: pointer;
    position: relative;
  }
  .contact-privacy__tit:after {
    content: "";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .contact-privacy__tit::after {
    position: absolute;
    right: 1rem;
    top: calc(50% - 10px);
    transition: all 0.3s ease 0s;
    font-size: 1.5rem;
  }
}

.contact-privacy__tit.active::after {
  transform: rotate(180deg);
}

@media (max-width: 1023px) {
  .contact-privacy__txt {
    border-top: 1px solid #979DA4;
  }
}

@media (min-width: 768px) {
  .contact-privacy .txt {
    font-size: 0.93em;
  }
}

@media (min-width: 1024px) {
  .contact-privacy .txt {
    padding: 0 10% 3rem;
  }
}

@media (max-width: 1023px) {
  .contact-privacy .txt {
    padding: 1rem 6% 3rem;
  }
}

/*-------------------------------------------------
	contact form
-------------------------------------------------*/
/* variablea */
.contact-form__tit {
  text-align: center;
  font-family: 'Noto Serif JP', serif;
  color: #0c0601;
  font-size: clamp(20px, 7.14vw, 32px);
  margin-bottom: clamp(2rem, 4vw, 3.5rem);
}

.contact-form .btn-wrap {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 576px) {
  .contact-form .btn-wrap {
    max-width: 320px;
  }
}

.contact-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2;
  position: relative;
}

.contact-table img {
  width: 100%;
}

.contact-table th,
.contact-table td {
  text-align: left;
}

@media (min-width: 1024px) {
  .contact-table th,
  .contact-table td {
    display: table-cell;
    padding: 1rem 0 2rem;
  }
}

@media (max-width: 1023px) {
  .contact-table th,
  .contact-table td {
    display: block;
  }
}

.contact-table th {
  vertical-align: top;
  font-weight: 400;
}

@media (min-width: 1024px) {
  .contact-table th {
    width: max(30%,280px);
  }
}

@media (min-width: 1536px) {
  .contact-table th {
    font-size: 1.12em;
  }
}

@media (max-width: 1023px) {
  .contact-table th {
    font-size: 1.12em;
  }
}

.contact-table th .required {
  padding: 0.2em 0.4em;
  line-height: 1;
}

@media (min-width: 1024px) {
  .contact-table td {
    width: 64%;
    padding-left: 4%;
  }
}

@media (max-width: 1023px) {
  .contact-table td {
    padding: 1.5rem 0 2.5rem;
    padding-top: 1.5rem;
  }
}

.contact-table textarea {
  width: 100%;
}

input,
select {
  width: auto;
}

input.long,
select.long {
  width: 100%;
}

input.middle,
select.middle {
  width: 100%;
}

@media (min-width: 768px) {
  input.middle,
  select.middle {
    width: 70%;
  }
}

input.short,
select.short {
  width: 100%;
}

@media (min-width: 768px) {
  input.short,
  select.short {
    width: 30%;
  }
}

input.mini,
select.mini {
  width: 10em;
}

button[class*="sub-btn"],
button[class*="back-btn"],
button[class*="chack-btn"] {
  font-size: 1.13rem;
  letter-spacing: 0.1em;
}

button[class*="sub-btn"] {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #D20F0F;
  border: 1px solid;
  border-color: #fdfbf6;
  padding: 1.5rem 4%;
  width: 100%;
  font-weight: 500;
}

button[class*="sub-btn"]:hover {
  color: #fff;
  background-color: #3B2909;
  border-color: #0c0601;
}

button[class*="sub-btn"]:hover::after {
  width: 4em;
  background-color: #0c0601;
}

button[class*="sub-btn"]::after {
  position: absolute;
  top: 50%;
  left: calc(100% - 1.5em);
  width: 3em;
  height: 1px;
  content: "";
  transition: all 0.3s ease 0s;
  background: #fdfbf6;
}

button[class*="back-btn"] {
  margin-top: 1rem;
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #4B4D4E;
  background-color: #e0e4e6;
  border: 1px solid;
  border-color: #979DA4;
  padding: 1.5rem 4%;
  width: 100%;
  font-weight: 500;
}

button[class*="back-btn"]:hover {
  color: #fff;
  background-color: #0c0601;
  border-color: #0c0601;
}

button[class*="back-btn"]:hover::after {
  width: 4em;
  background-color: #0c0601;
}

button[class*="back-btn"]::after {
  position: absolute;
  top: 50%;
  left: calc(100% - 1.5em);
  width: 3em;
  height: 1px;
  content: "";
  transition: all 0.3s ease 0s;
  background: #979DA4;
}

button[class*="chack-btn"] {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #D20F0F;
  border: 1px solid;
  border-color: #fdfbf6;
  padding: 1.5rem 4%;
  width: 100%;
  font-weight: 500;
}

button[class*="chack-btn"]:hover {
  color: #fff;
  background-color: #3B2909;
  border-color: #0c0601;
}

button[class*="chack-btn"]:hover::after {
  width: 4em;
  background-color: #0c0601;
}

button[class*="chack-btn"]::after {
  position: absolute;
  top: 50%;
  left: calc(100% - 1.5em);
  width: 3em;
  height: 1px;
  content: "";
  transition: all 0.3s ease 0s;
  background: #fdfbf6;
}

[type="button"],
[type="submit"] {
  -webkit-appearance: none;
}

/* radio-btn */
.mwform-radio-field:not(:last-child) {
  margin-right: 1em;
}

.mwform-radio-field label {
  cursor: pointer;
}

.mwform-radio-field-text {
  vertical-align: top;
  font-size: 1.07em;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

input[type="radio"] + .mwform-radio-field-text:before {
  content: "";
  background: #fdfdfd;
  border-radius: 100%;
  border: 1px solid #979DA4;
  display: inline-block;
  width: 26px;
  height: 26px;
  position: relative;
  top: -0.1em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease;
}

input[type="radio"]:checked + .mwform-radio-field-text:before {
  background-color: #D20F0F;
  box-shadow: inset 0 0 0 5px #f6f6f6;
}

input[type="radio"]:focus + .mwform-radio-field-text:before {
  outline: none;
  border-color: #D20F0F;
}

input[type="radio"]:disabled + .mwform-radio-field-text:before {
  box-shadow: inset 0 0 0 4px #979DA4;
  border-color: #979DA4;
  background: #fdfdfd;
}

input[type="radio"] + .mwform-radio-field-text:empty:before {
  margin-right: 0;
}

.vertical-item:not(:first-of-type) {
  margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}

@-webkit-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}

@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}

@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}

@-webkit-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}

@-moz-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}

input[type="checkbox"] {
  display: none;
}

.check-box .vertical-item:not(:first-of-type) {
  margin-top: 1.5rem !important;
}

.check-box label {
  position: relative;
  cursor: pointer;
}

.check-box label::before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: transparent;
  border: 1px solid #979DA4;
  margin-right: 1em;
  position: absolute;
  top: -0.15em;
  left: 0;
  display: block;
  transition: border-color ease 0.2s;
  z-index: 1;
}

.mwform-checkbox-field-text {
  vertical-align: top;
  padding-left: calc(26px + 1rem);
  display: block;
  font-size: 1.07em;
}

.mwform-checkbox-field-text::before, .mwform-checkbox-field-text::after {
  position: absolute;
  height: 0;
  width: 5.2px;
  background-color: #D20F0F;
  display: inline-block;
  transform-origin: left top;
  content: "";
  transition: opacity ease 0.5;
  z-index: 2;
}

.mwform-checkbox-field-text::before {
  top: 22.36px;
  left: 10.4px;
  transform: rotate(-135deg);
}

.mwform-checkbox-field-text::after {
  top: 13px;
  left: 0.78px;
  transform: rotate(-45deg);
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
  border-color: #D20F0F;
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text::after,
.mwform-checkbox-field-text.checked::after {
  height: 13px;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text::before,
.mwform-checkbox-field-text.checked::before {
  height: 31.2px;
  animation: dothatopcheck 0.4s ease 0s forwards;
  box-shadow: 0 0 0 1.3px #fff;
}

.mw_wp_form .error {
  margin-top: .7rem;
}

/* form-parts
----------------------------------------------------------------*/
input, textarea, select {
  background-color: #fdfdfd;
  padding: 1em;
  border: 1px solid #979DA4;
  line-height: 1.5;
}

input:focus, textarea:focus, select:focus {
  background-color: #fff;
  box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

input::-webkit-input-placeholder, input:-moz-placeholder, input:-ms-input-placeholder {
  color: #979DA4;
}

textarea {
  height: 10em;
}
