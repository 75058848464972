/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  padding: 2rem 0;
  position: relative;
  z-index: z-index(module,part03);
  @include media-breakpoint-up(lg) {
    padding: 3rem 0;
  }
  &-list {
    @include flex-wrap;
    font-size: 0.9em;
    & .icon-home {
      font-size: 1.1em;
    }
    li {
      position: relative;
      &:not(:last-child) {
        padding-right: 1.5rem;
        @include icon(right_arrow,after);
        &::after {
          position: absolute;
          right:0.3rem;
          top:50%;
          transform: translateY(-50%);
        }
      }
    }
    a {
      color: $gray;
      @include transition;
      vertical-align: top;
      &:first-child {
        @include dec-none;
      }
      &:hover {
        color: $main_c;
      }
    }
  }
}
