/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
aside {
	@include media-breakpoint-up(lg) {
		position: sticky;
		top: 120px;
		left: 0;
	}
}

.aside-widget {
	&:not(:last-child) {
		margin-bottom: 2.5rem;
	}
	&__tit {
		padding: 0.3rem 0 0.5rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid $m-gray;
		@include f-w(500);
		@include f-em(18);
	}
	ul {
		li {
			a {
				padding-left: 1.3em;
				color: $txt_c;
				@include dec-none;
				position: relative;
				display: block;
				&::before {
					content: "●";
					position: absolute;
					font-size: 0.8em;
					top: .15em;
					left: 0;
					@include f-em(14);
					padding-right: 0.2em;
					color: $gray;
					@include dec-none;
				}
				&:hover {
					@include dec-line;
				}
			}
		}
	}
}
.tag-cloud-link {
	font-size: 1rem !important;
	color: $txt_c;
	padding-left: 1.5rem;
	margin-right: 0.5rem;
	position: relative;
	@include icon(tag, before);
	@include dec-none;
	&::before {
		position: absolute;
		top: 0.6em;
		left: 0.2rem;
		@include f-em(14);
		color: $gray;
		@include dec-none;
	}
	&:hover {
		@include dec-line;
	}
}

/* calendar
------------------------------------*/
#wp-calendar {
	border-collapse: separate;
	width: 100%;
}
.calendar_wrap {
	padding: 0.5rem 0.75rem;
	border: 1px solid $m-gray;
}
.wp-calendar {
	&-table {
		//テーブル
		margin-bottom: 0.75rem;
		caption {
			padding: 0.3rem 2%;
		}
		& thead {
			th {
				text-align: center;
				padding: 6px;
				width: auto;
				color: $gray;
				@include f-w(500);
			}
		}
		td {
			text-align: center;
			background: $p-gray;
			line-height: 2;
			border: 2px solid $white;
			@include f-em(15);
			& a {
				background-color: $main_c;
				color: $white;
				@include transition;
				display: block;
				@include dec-none;
				&:hover {
					background-color: $sub_c;
				}
			}
			&.pad {
				background: transparent;
			}
			&#today {
				background: $gray;
				color: $white;
			}
		}
	}
	&-nav {
		@include flex-between;
		width: 100%;
		padding-bottom: 0.3rem;
		a {
			display: block;
			color: $txt_c;
			padding: 0.4rem 1rem;
			background-color: $p-gray;
			@include dec-none;
			@include transition;
			&:hover {
				background-color: $gray;
				color: $white;
			}
		}
	}
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.wp-block-search__inside-wrapper {
	position: relative;
	width: 100%;
	border: 1px solid $gray !important;
	padding: 0 !important;
	display: flex;
	justify-content: space-between;
}
.wp-block-search__input {
	height: 40px;
	padding: 0 10px !important;
	border-radius: 2px !important;
	outline: 0;
	background: $white;
	appearance: none;
	border: none;
	width: calc(100% - 50px);
}

.wp-block-search__button {
	height: 40px !important;
	width: 50px !important;
	padding: 2px !important;
	background: none !important;
	font-size: 18px !important;
	vertical-align: bottom !important;
	color: $m-gray !important;
	background: $white !important;
	@include transition;
	border: none !important;
	text-align: center;
	cursor: pointer !important;
	&:hover {
		color: $white !important;
		background-color: $main_c !important;
	}
}

/*--------------------------------------------------------------------/
	archives
/--------------------------------------------------------------------*/
.wp-block-archives {
	> label {
		display: none !important;
	}
	select {
		width: 80% !important;
		@include media-breakpoint-down(md) {
			width: 100% !important;
		}
	}
}
